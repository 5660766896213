import Vue from 'vue';
import Vuex from 'vuex';
import AppStore from './app.store';
import BasketStore from './basket.store';
import QuoteBasketStore from './quote-basket.store';
import CatalogStore from './catalog.store';
import CheckoutB2CStore from './checkout.b2c.store';
import CheckoutB2BStore from './checkout.b2b.store';
import DownloadCenterStore from './download-center.store';
import ProjectStore from './project.store';
import SearchStore from './search.store';
import GdprStore from './gdprform.store';
import TraderFormStore from './traderform.store';
import ReturnFormStore from './returnform.store';
import WarrantyFormStore from './warrantyform.store';
import navigationStore from './navigation.store';
import HideB2BPricesStore from './hide-b2b-prices.store';
import OrderViewStore from './order-view.store';
import RequestMeetingForm from './requestmeetingform.store'

Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: true,
    mutations: {
        sync(state, entry) {
            state[entry.namespace][entry.property] = entry.data;
        }
    },
    modules: {
        app: AppStore,
        catalog: CatalogStore,
        basket: BasketStore,
        quotebasket: QuoteBasketStore,
        checkoutb2c: CheckoutB2CStore,
        checkoutb2b: CheckoutB2BStore,
        downloadCenter: DownloadCenterStore,
        search: SearchStore,
        gdprform: GdprStore,
        requestMeetingForm: RequestMeetingForm,
        traderform: TraderFormStore,
        returnform: ReturnFormStore,
        warrantyform: WarrantyFormStore,
        navigation: navigationStore,
        project: ProjectStore,
        hideb2bprices: HideB2BPricesStore,
        orderViewStore: OrderViewStore
    }
});

export * from './app.store';
export * from './catalog.store';
export * from './checkout.b2c.store';
export * from './checkout.b2b.store';
export * from './download-center.store';
export * from './search.store';
export * from './gdprform.store';
export * from './traderform.store';
export * from './returnform.store';
export * from './warrantyform.store';
export * from './navigation.store';
export * from './basket.store';
export * from './quote-basket.store';
export * from './project.store';
export * from './router.store';
export * from './hide-b2b-prices.store';
export * from './order-view.store';
export * from './requestmeetingform.store';
