import { ActionTree } from 'vuex';
import { namespace } from 'vuex-class';
import RequestMeetingFormService from '@/services/requestmeetingform.service';

const actions: ActionTree<any, any> = {
    async submitRequestMeetingForm({ commit }, request: RequestMeetingFormBlockWebRequest) {
        return await RequestMeetingFormService.submitForm(request);
    }
};

export default {
    namespaced: true,
    actions
};

const moduleName = 'requestMeetingForm';
const moduleObject = namespace(moduleName);

export const RequestMeetingForm = moduleObject.Action;
