import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=591af0ba&scoped=true&"
import script from "./ProductTile.vue?vue&type=script&lang=ts&setup=true&"
export * from "./ProductTile.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./ProductTile.vue?vue&type=style&index=0&id=591af0ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591af0ba",
  null
  
)

export default component.exports